<template>
  <van-overlay :lock-scroll="false" :show="show" z-index="10" @click="onCancel">
    <div class="zdd-company-update" @click.stop>
      <div class="zdd-company-update-header">
        <div class="zdd-company-update-header-title">选择身份</div>
        <van-button type="primary" size="small" round plain @click="handleCreateCom">
          <van-icon name="add" class="icon" />
          创建企业
        </van-button>
      </div>
      <div class="zdd-company-update-company-list">
        <div class="zdd-company-update-company-list-item" :class="{ checked: curSelectComId == null }" :key="0"
          @click="onSelectComId({ comId: null })">
          <span class="com-name">
            <img v-if="userInfo.creditStatus == 1" src="@/assets/imgs/credit_icon.png" alt="" />
            <span v-else class="com-status">未认证</span>
            个人身份（{{ userInfo.name || userInfo.telephone }})
          </span>
          <SvgIcon type="zdd_profile_company_checked" class="icon" v-show="curSelectComId == null" />
        </div>
        <div class="zdd-company-update-company-list-item" :class="{ checked: company.comId === curSelectComId }"
          v-for="company in companyList" :key="company.comId" @click="onSelectComId(company)">
          <span class="com-name">
            <img v-if="company.creditStatus == 1" src="@/assets/imgs/credit_icon.png" alt="" />
            <span v-else class="com-status">未认证</span>
            {{ company.comName }}
          </span>
          <SvgIcon type="zdd_profile_company_checked" class="icon" v-show="company.comId === curSelectComId" />
        </div>
      </div>
      <div class="zdd-company-update-footer">
        <van-button @click="onCancel">取消</van-button>
        <van-button type="primary" @click="onConfirm">确定</van-button>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import getCreditToken from '@/utils/getCreditToken'
import { Dialog } from 'vant'


export default {
  name: 'ZddCompanyUpdate',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    curComId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      show: false,
      curSelectComId: this.curComId,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.open()
        } else {
          this.close()
        }
      },
      immediate: true,
    },
    show: {
      handler(newValue) {
        this.$emit('input', newValue)
        if (newValue) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      companyList: 'companyList',
      userInfo: 'getUserInfo',
      isComCredit: 'isComCredit',
    }),
  },
  created() { },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    onSelectComId(company) {
      const { comId } = company
      this.curSelectComId = comId
    },
    resetSelectComId() {
      this.curSelectComId = this.curComId
    },
    onCancel() {
      this.close()
      let timer = setTimeout(() => {
        this.resetSelectComId()
        timer = null
      }, 300)
    },
    async onConfirm() {
      let loadingInstance
      try {
        if (this.curSelectComId !== this.userInfo.comId) {
          loadingInstance = this.$toast.loading({
            message: '处理中',
            loadingType: 'spinner',
            duration: 90000,
          })
          await this.$store.dispatch(
            'updateCurrentIdentity',
            this.curSelectComId
          )
          this.$emit('updateSuccess', this.curSelectComId)
        }
        this.close()
      } catch (error) {
        console.error(error)
      } finally {
        if (loadingInstance) {
          loadingInstance.close()
        }
      }
    },

    async handleCreateCom() {
      this.close()
      this.$router.push({
        name: 'CreateCompany',
      })
    },
  },
  mounted() {
    console.log('curSelectComId: ', this.curSelectComId);
  }
}
</script>

<style scoped lang="less">
.zdd-company-update {
  padding: 20px 16px 64px;
  background: @WHITE;
  width: 100%;
  height: 300px;
  position: relative;

  .zdd-company-update-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .zdd-company-update-header-title {
      font-size: 16px;
      font-weight: 500;
      color: #4b505f;
      line-height: 22px;
    }

    .van-button {
      height: 26px;

      .van-button__text {
        display: flex;
        align-items: center;
        font-size: 13px;

        .icon {
          font-size: 14px;
          margin-right: 4px;
        }
      }
    }
  }

  .zdd-company-update-company-list {
    margin-top: 16px;
    height: 166px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    touch-action: auto;

    .zdd-company-update-company-list-item {
      background: @WHITE;
      border: 1px solid #c1c4cd;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 12px;

      .com-name {
        font-size: 13px;
        font-weight: 500;
        color: #111a34;
        line-height: 18px;

        .com-status {
          color: #f15643;
          font-size: 12px;
          margin-right: 6px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }

      &.checked {
        border: 1px solid var(--main-color);

        .com-name {
          color: var(--main-color);
        }
      }

      .icon {
        font-size: 14px;
      }
    }

    .zdd-company-update-company-list-item+.zdd-company-update-company-list-item {
      margin-top: 10px;
    }
  }

  .zdd-company-update-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;

    .van-button {
      height: 56px;
      flex: 1;
      box-shadow: 0px -7px 7px 0px rgba(247, 247, 247, 0.96);
      border-radius: 0;
    }
  }
}
</style>
