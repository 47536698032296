<template>
  <div class="view-fileList">
    <div class="topSticky">
      <div class="zdd-home-header-identity" @click="handleShowCompanyUpdate">
        <div class="zdd-home-header-identity-current" v-if="isLogin">
          <div class="zdd-home-header-identity-current-name">
            Hi，
            <template v-if="isComUser">
              {{ comName }}
            </template>
            <template v-else>
              {{ userName || telephone }}
            </template>
          </div>

          <span class="arrow"></span>
        </div>
        <div class="zdd-home-header-identity-current" v-else @click="handleToLogin">
          请先登录/注册
        </div>
        <div class="zdd-home-header-identity-change-button" v-if="isLogin">
          <van-button class="button-item" type="primary" size="mini" plain round v-if="!isComUser && !companyList.length"
            @click="handleToCreateCompanyPage">
            <van-icon name="add" />创建企业</van-button>
          <van-button class="button-item" type="info" size="mini" round v-if="isComUser && !isComCredit"
            @click.stop="handleToComCreditPage">
            认证企业</van-button>
        </div>
      </div>
      <!-- <div class="banner" v-if="bannerShow">
        <img src="@/assets/imgs/banner.png" alt @click="handelBannnerDialog" />
        <span @click="handelCloseBannner"></span>
      </div> -->
      <van-search v-model="params.magicName" shape="round" placeholder="输入标题/文件名/签署方名称" left-icon="" right-icon="search"
        @search="onSearch" @cancel="onCancel" :disabled="!isLogin" />
      <van-dropdown-menu class="menu">
        <van-dropdown-item :title="getTitle('fileStatus')" ref="fileStatus">
          <ul class="menu-list">
            <li v-for="(item, index) in fileStatusList" :key="index" :class="{ active: params.fileStatus == item.value }"
              @click="changefileStatus(item)">
              {{ item.label }}
            </li>
          </ul>
        </van-dropdown-item>
        <van-dropdown-item :title="getTitle('signRole')" ref="signRole">
          <ul class="menu-list">
            <li v-for="(item, index) in signRoleList" :key="index" :class="{ active: params.signRole == item.value }"
              @click="changeSignRole(item)">
              {{ item.label }}
            </li>
          </ul>
        </van-dropdown-item>
        <van-dropdown-item title="时间" ref="time">
          <ul class="timeList">
            <li>
              <div class="title">截止时间</div>
              <div class="timeForm">
                <div class="item" @click="changeTime('byStartTime')">
                  <div class="label">开始</div>
                  <div class="time">{{ getDay(params.byStartTime) }}</div>
                </div>
                <img src="@/assets/imgs/right.svg" alt class="right" />
                <div class="item" @click="changeTime('byEndTime')">
                  <div class="label">结束</div>
                  <div class="time">{{ getDay(params.byEndTime) }}</div>
                </div>
              </div>
            </li>
            <li>
              <div class="title">发起时间</div>
              <div class="timeForm">
                <div class="item" @click="changeTime('launchStartTime')">
                  <div class="label">开始</div>
                  <div class="time">{{ getDay(params.launchStartTime) }}</div>
                </div>
                <img src="@/assets/imgs/right.svg" alt class="right" />
                <div class="item" @click="changeTime('launchEndTime')">
                  <div class="label">结束</div>
                  <div class="time">{{ getDay(params.launchEndTime) }}</div>
                </div>
              </div>
            </li>
          </ul>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="" @load="getList">
      <ul class="list">
        <li v-for="(item, index) in list" :key="index" @click="goDetail(item)" :class="{ draft: item.status == 0 }">
          <div class="top">
            <div class="masterTitle">
              {{ item.masterTitle || '无标题' }}
            </div>
            <div class="status" :class="'status' + item.status">
              <img v-if="item.status" :src="require('@/assets/imgs/status' +
                `${item.status >= 3 && item.status != 8
                  ? 3
                  : item.status == 8
                    ? 1
                    : item.status
                }` +
                '.png')
                " alt="" />
              {{ statusList[item.status] }}
            </div>
          </div>
          <div class="bottom" v-if="item.status">
            <div class="detail">
              <div class="launcher">
                <span> {{ item.launcher }}发起</span>
                <template v-if="item.status == 1">
                  ：<span>{{ item.toHandler }}处理中</span>
                </template>
              </div>
              <div class="byTime" v-if="item.status == 1">
                {{ item.leftTime }}
              </div>
            </div>
            <div class="time">
              <template v-if="item.status == 1 || item.status == 8">
                截止时间：{{ getTime(item.byTime * 1000) }}
              </template>
              <template v-else-if="item.status == 2">
                完成时间：{{ getTime(item.finishTime * 1000) }}
              </template>
              <template v-else-if="item.status == 3">
                失效时间：{{ item.loseEffectiveTime }}
              </template>
            </div>
          </div>
        </li>
        <div class="listNull" v-if="!list.length && !loading">
          <SvgIcon v-if="isZdd" type="zdd_no_record_list" class="no-data-img" />
          <img v-else src="@/assets/imgs/listNull.png" alt class="right" />
          <div>{{ isZdd ? '暂无证书' : '暂无文件' }}</div>
        </div>
      </ul>
    </van-list>
    <van-popup @click.stop="1" round v-model="popupShow" position="bottom" get-container="body" @close="closeTime">
      <van-datetime-picker v-if="timeName == 'byStartTime'" class="dateTimePicker" title="开始时间" v-model="params[timeName]"
        type="date" @confirm="timeConfirm(timeName)" @cancel="timeCancel(timeName)" />
      <van-datetime-picker v-if="timeName == 'byEndTime'" class="dateTimePicker" title="结束时间" v-model="params[timeName]"
        type="date" @confirm="timeConfirm(timeName)" @cancel="timeCancel(timeName)" />
      <van-datetime-picker v-if="timeName == 'launchStartTime'" class="dateTimePicker" title="开始时间"
        v-model="params[timeName]" type="date" @confirm="timeConfirm(timeName)" @cancel="timeCancel(timeName)" />
      <van-datetime-picker v-if="timeName == 'launchEndTime'" class="dateTimePicker" title="结束时间"
        v-model="params[timeName]" type="date" @confirm="timeConfirm(timeName)" @cancel="timeCancel(timeName)" />
    </van-popup>

    <ZddCompanyUpdate v-if="isLogin" v-model="showZddCompanyUpdate" :cur-com-id="isLogin ? userInfo.comId : null"
      @updateSuccess="getList(1)" />
    <van-popup v-model="dialogShow">
      <img src="@/assets/imgs/banner_dialog.png" class="bannerDialogImg" />
    </van-popup>
  </div>
</template>

<script>
import ZddCompanyUpdate from '@/components/Zdd/ZddCompanyUpdate.vue'
import contractBusinessApi from '@/api/contractBusiness'
import moment from 'moment'
import {
  miniLogin,
  navigateToMiniLogin,
  postShareMessageToMini,
} from '@/utils/mini'
import { isWxMiniProgram } from '@/utils/ua'
import { mapGetters } from 'vuex'
import { hasCookies } from '@/utils/cookies'

export default {
  components: {
    ZddCompanyUpdate,
  },
  data() {
    return {
      fileStatusList: [
        { label: '全部', value: 0 },
        { label: '草稿', value: 1 },
        { label: '待我处理', value: 2 },
        { label: '待他人处理', value: 3 },
        { label: '未完成', value: 6 },
        { label: '已完成', value: 4 },
        { label: '已失效', value: 5 },
        // { label: '已失效-已作废', value: 7 },
        // { label: '作废中', value: 8 },
      ],
      signRoleList: [
        { label: '全部', value: 0 },
        { label: '我是发起方', value: 1 },
        { label: '我是参与方', value: 2 },
        { label: '我是被抄送人', value: 3 },
      ],
      params: {
        pageNum: 1,
        pageSize: 10,
        magicName: null, // 标题/文件名/签署方名称
        fileStatus: 2, // 文件状态
        signRole: 0, // 签署角色
        byStartTime: null, // 截至开始时间
        byEndTime: null,
        launchStartTime: null, // 发起开始时间
        launchEndTime: null,
        batchOperateType: null, // 批量操作类型 1.批量盖章
      },
      loading: false,
      finished: true,
      list: [],
      total: 0,
      statusList: {
        0: '草稿',
        1: '未完成',
        2: '已完成',
        3: '已失效',
        4: '已失效',
        5: '已失效',
        6: '已失效',
        7: '已失效-已作废',
        8: '作废中',
      },
      timeName: null,
      popupShow: false,

      showZddCompanyUpdate: false,
      bannerShow: true,
      dialogShow: false,
    }
  },
  computed: {
    ...mapGetters({
      companyList: 'companyList',
    }),
    userInfo() {
      return this.$store.getters.getUserInfo
    },
    isZdd() {
      return this.$store.getters['system/isZdd']
    },
    isLogin() {
      return !!this.$store.getters.getUserInfo.userId
    },
    userName() {
      return this.userInfo.name
    },
    telephone() {
      if (this.userInfo.telephone) {
        return this.userInfo.telephone.replace(
          /(\d{3})(\d{4})(\d{4})/,
          '$1****$3'
        )
      }
      return ''
    },
    comName() {
      return this.$store.getters.getUserInfo.comName
    },
    isCredit() {
      return this.$store.getters.isCredit
    },
    isComUser() {
      return this.$store.getters.isComUser
    },
    isComCredit() {
      return this.$store.getters.isComCredit
    },
    hasRealName() {
      return this.$store.getters.hasRealName
    },
    // 账号激活状态 需要激活 true 无需激活 false
    activationStatus() {
      return this.isLogin && !this.userInfo.telephone && !this.userInfo.email
    },
    fileStatus() {
      return this.$route.query.fileStatus
    },
  },
  created() { },
  activated() {
    console.log('activated')
    this.getList(1)
  },
  mounted() {
    if (this.fileStatus !== undefined) {
      console.log('fileStatus: ', this.fileStatus)
      this.params.fileStatus = Number(this.fileStatus)
      console.log('this.params.fileStatus: ', this.params.fileStatus)
      this.getList(1)
    }
    const search = document.getElementsByClassName('van-icon-search')[0]
    search.addEventListener('click', this.onSearch)

    if (isWxMiniProgram()) {
      // 通知 小程序 分享页面
      postShareMessageToMini({
        title: '易云章',
        content: '',
        url: encodeURIComponent(this.$route.path),
      })
    }
    this.bannerShow = !localStorage.getItem('bannerNoticeShow')
  },

  methods: {
    // 关闭告知书
    handelCloseBannner() {
      this.bannerShow = false
      localStorage.setItem('bannerNoticeShow', 1)
    },
    // 查看告知书
    handelBannnerDialog() {
      this.dialogShow = true
    },
    // 搜索业务列表
    async getList(page) {
      try {
        if (!hasCookies()) {
          this.list = []
          this.loading = false
          this.finished = true
          this.params.pageNum = 1
          return
        }
        if (this.loading) return
        if (page) {
          this.list = []
          this.loading = true
          this.finished = false
          this.params.pageNum = page
        }
        const obj = { ...this.params }
        obj.byStartTime = obj.byStartTime ? obj.byStartTime / 1000 : null
        obj.byEndTime = obj.byEndTime ? obj.byEndTime / 1000 : null
        obj.launchStartTime = obj.launchStartTime
          ? obj.launchStartTime / 1000
          : null
        obj.launchEndTime = obj.launchEndTime
          ? obj.launchEndTime / 1000
          : null
        const data = await contractBusinessApi.list(obj)
        this.params.pageNum += 1
        if (page) {
          this.list = data.rowsList
        } else {
          this.list = this.list.concat(data.rowsList)
        }
        this.total = data.total

        // 数据全部加载完成
        if (this.list.length >= this.total) {
          this.finished = true
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    goDetail(item) {
      if (this.activationStatus) {
        // 登录状态且账号未激活，跳转至账号激活页面
        this.$router.push({
          name: 'qywxAccountActivation',
        })
        return
      }
      if (item.status === 0) {
        if (item.simpleLaunch) {
          this.$toast('本条草稿暂不支持移动端编辑，请在PC端编辑')
          return
        }
        this.$toast('本条草稿暂不支持移动端编辑')
        return
      }
      this.$router.push({
        path: '/signdetail',
        query: {
          businessId: item.businessId,
          fileStatus: item.status,
        },
      })
    },
    onSearch(val) {
      this.getList(1)
    },
    onCancel() {
      this.params.magicName = null
      this.getList(1)
    },
    changefileStatus(item) {
      this.params.fileStatus = item.value
      this.$refs.fileStatus.toggle()
      this.getList(1)
    },
    changeSignRole(item) {
      this.params.signRole = item.value
      this.$refs.signRole.toggle()
      this.getList(1)
    },
    closeTime() {
      this.params[this.timeName] = null
      this.timeName = null
      this.popupShow = false
    },
    timeCancel(timeName) {
      this.timeName = null
      this.popupShow = false
      this.params[timeName] = null
      this.getList(1)
    },
    timeConfirm(timeName) {
      console.log(timeName)
      this.timeName = null
      this.popupShow = false
      this.params[timeName] = moment(this.params[timeName]).valueOf()
      this.getList(1)
    },
    changeTime(timeName) {
      this.timeName = timeName
      this.params[timeName] = this.params[timeName]
        ? new Date(this.params[timeName])
        : new Date()
      this.popupShow = true
    },
    getDay(timeStamp) {
      if (timeStamp) {
        return moment(timeStamp).format('YYYY/MM/DD')
      }
      return '请选择时间'
    },
    getTime(timeStamp) {
      return moment(timeStamp).format('YYYY/MM/DD HH:mm:ss')
    },
    getTitle(name) {
      const index = this[`${name}List`].findIndex(
        e => e.value === this.params[name]
      )
      if (!index) {
        if (name === 'fileStatus') {
          return '状态'
        }
        if (name === 'signRole') {
          return '角色'
        }
      }
      return this[`${name}List`][index].label
    },

    handleToLogin() {
      if (isWxMiniProgram()) {
        navigateToMiniLogin(true, '/HomePage')
      } else {
        this.$router.push({
          name: 'Login',
        })
      }
    },

    handleShowCompanyUpdate() {
      // console.log('点击');
      // 登录状态且账号未激活，跳转至账号激活页面
      if (this.activationStatus) {
        this.$router.push({
          name: 'qywxAccountActivation',
        })
        return
      }
      this.showZddCompanyUpdate = true
    },

    handleToComCreditPage(e) {
      e.stopPropagation()
      this.$router.push({
        name: 'AuthSubmit',
      })
    },

    handleToCreateCompanyPage(e) {
      // 登录状态且账号未激活，跳转至账号激活页面
      if (this.activationStatus) {
        this.$router.push({
          name: 'qywxAccountActivation',
        })
        return
      }
      e.stopPropagation()
      this.$router.push({
        name: 'CreateCompany',
      })
    },
  },
}
</script>

<style lang="less" scoped>
.view-fileList {
  width: 100%;
  background: #f4f5f6;
  box-sizing: border-box;
  padding-bottom: 50px;

  .topSticky {
    position: sticky;
    top: 0;
    left: 0;
  }

  .zdd-home-header-identity {
    width: 100%;
    height: 55px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: @WHITE;

    &-current {
      font-weight: 600;
      color: @TEXT-COLOR-0;
      font-size: 16px;
      display: flex;
      align-items: center;

      .zdd-home-header-identity-current-name {
        max-width: 135px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .arrow {
        margin-left: 7px;
        display: inline-flex;
        align-items: center;
        border-top: 5px solid #111a34;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 0;
      }
    }

    &-change-button {
      margin-left: 12px;
      display: flex;

      .button-item {
        padding: 0 8px;

        .van-button__content {
          .van-button__text {
            display: inline-flex;
            align-items: center;
            font-size: 13px;

            .van-icon {
              margin-right: 4px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .banner {
    position: relative;

    img {
      width: 100%;
    }

    span {
      position: absolute;
      right: 6px;
      top: 8px;
      width: 20px;
      height: 20px;
    }
  }

  /deep/ .van-search {
    padding: 8px 16px;

    /deep/ .van-search__content {
      padding-left: 16px;
    }

    /deep/ .van-cell {
      padding: 8px 16px 8px 0;

      /deep/ .van-cell__value {
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        line-height: 16px;
      }

      /deep/ .van-field__control {
        color: #434751;
      }

      /deep/ .van-field__control::-webkit-input-placeholder {
        color: rgba(67, 71, 81, 0.6);
      }
    }

    /deep/ .van-field__right-icon {
      color: #434751;
    }
  }

  .menu {
    /deep/ .van-dropdown-menu__bar {
      box-shadow: none;
    }

    /deep/ .van-dropdown-menu__item {
      /deep/ .van-dropdown-menu__title::after {
        border-color: transparent transparent #434751 #434751;
      }

      /deep/ .van-dropdown-menu__title--active {
        color: #1676ff;
      }

      /deep/ .van-dropdown-menu__title--active::after {
        border-color: transparent transparent #1676ff #1676ff;
      }
    }

    /deep/ .van-dropdown-menu__title {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #111a34;
    }

    .menu-list {
      padding: 0 0 16px 24px;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 96px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(64, 76, 86, 0.2);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #111a34;
        margin-top: 16px;
      }

      .active {
        border: 1px solid #1676ff;
        color: #1676ff;
      }
    }

    .timeList {
      padding: 0 24px 16px;

      li {
        .title {
          height: 47px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: left;
          color: #3c4043;
          display: flex;
          align-items: center;
        }

        .timeForm {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .item {
            width: 139px;
            height: 58px;
            background: #f4f5f6;
            border-radius: 2px;
            box-sizing: border-box;
            padding: 9px 18px;

            .label {
              font-size: 11px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #8a9ab4;
              line-height: 14px;
              letter-spacing: 0px;
              margin-bottom: 7px;
            }

            .time {
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #111a34;
              line-height: 20px;
              letter-spacing: 0px;
            }
          }

          .right {
            width: 13px;
          }
        }
      }
    }
  }

  .list {
    padding: 8px;

    li {
      margin-bottom: 8px;
      width: 100%;
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 5px 20px 0px rgba(197, 202, 213, 0.25);
      padding: 0 12px;

      .top {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .masterTitle {
          width: 160px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: left;
          color: #111a34;
          line-height: 24px;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          -webkit-line-clamp: 1;
          /*! autoprefixer: ignore next */
          -webkit-box-orient: vertical;
        }

        .status {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: left;
          color: #ff6e48;
          line-height: 20px;
          display: flex;
          align-items: center;

          img {
            width: 12px;
            margin-right: 4px;
          }
        }

        .status0 {
          color: #16bb83;
        }

        .status1,
        .status8 {
          color: #ff6e48;
        }

        .status2 {
          color: #1676ff;
        }

        .status3,
        .status7 {
          color: #c5c5c5;
        }
      }

      .bottom {
        border-top: 1px solid #f5f5f5;
        padding: 11px 0;

        .detail {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;

          .launcher {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #404c56;
            line-height: 16px;
            display: flex;

            span {
              max-width: 140px;
              display: -webkit-box;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              -webkit-line-clamp: 1;
              /*! autoprefixer: ignore next */
              -webkit-box-orient: vertical;
            }
          }

          .byTime {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #677283;
            line-height: 16px;
            flex-shrink: 0;
          }
        }

        .time {
          font-size: 10px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #8a9ab4;
          line-height: 16px;
        }
      }
    }

    .draft {
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .listNull {
      text-align: center;
      padding-top: 69px;

      .no-data-img {
        display: flex;
        justify-content: center;

        /deep/ svg {
          display: flex;
          width: 240px;
          height: 88px;
        }
      }

      img {
        width: 240px;
        margin-bottom: 4px;
      }

      div {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #c1c4cd;
        line-height: 20px;
      }
    }
  }

  .bannerDialogImg {
    height: 80vh;
  }
}

.dateTimePicker {
  /deep/ .van-picker__toolbar {
    height: 56px;
    background: #f4f5f6;

    /deep/ .van-picker__cancel {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 20px;
    }

    /deep/ .van-picker__confirm {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: right;
      color: #1676ff;
      line-height: 20px;
    }

    /deep/ .van-picker__title {
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: bold;
      text-align: center;
      color: #41485d;
      line-height: 28px;
    }
  }

  /deep/ .van-picker__columns {
    /deep/ .van-picker-column__item {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #8a9ab4;
      line-height: 22px;
    }

    /deep/ .van-picker-column__item--selected {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: bold;
      text-align: center;
      color: #2f86f6;
      line-height: 25px;
    }

    /deep/ .van-picker__frame {
      border-top: 1px solid rgba(64, 76, 86, 0.3);
      border-bottom: 1px solid rgba(64, 76, 86, 0.3);
    }
  }
}
</style>
