import AuthApi from '@/api/creadit'

export default function(type) {
  const creditToken = window.sessionStorage.getItem('creditToken') || ''
  // 0需要再次登录(我不是法人)，1我是法人，2个人认证)
  return AuthApi.getCreditToken({
    type,
    creditToken,
  }).then(res => {
    window.sessionStorage.setItem('creditToken', res.creditToken)
    return res.creditToken
  })
}
